<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item :disabled="true">Absences</vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <vue-good-table
                :is-loading="absencesListIsUpdating"
                :columns="columns"
                :rows="absencesList"
                :pagination-options="pagination"
                styleClass="vgt-table striped bordered condensed"
            >
                <template slot="loadingContent">
                    <span class="vgt-loading__content">Téléchargement...</span>
                </template>
                <template v-slot:table-row="props">
                    <div v-if="props.column.field === 'startDate'">
                        {{ `${$util.formatDate(props.row.startDate, 'DD/MM/YYYY', true)}` }}
                    </div>
                    <div v-else-if="props.column.field === 'endDate'">
                        <div>
                            {{ `${$util.formatDate(props.row.endDate, 'DD/MM/YYYY', true)}` }}
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'delegatorUser.userInfos.firstName'">
                        <div>
                            {{
                                props.row.delegatorUser.userInfos.firstName +
                                ' ' +
                                props.row.delegatorUser.userInfos.lastName
                            }}
                        </div>
                    </div>
                    <div v-else-if="props.column.field === 'actions'">
                        <div class="d-flex justify-content-center">
                            <i
                                style="margin-right: 20px"
                                class="fa-solid fa-pen-to-square"
                                @click="onUpdate(props.row)"
                            ></i>
                            <i class="fa-solid fa-trash" @click="onDelete(props.row)"></i>
                        </div>
                    </div>
                </template>
                <div slot="table-actions" class="p-2 pt-0 pb-0 d-md-flex flex-row">
                    <div class="d-flex align-items-center dpmargin">
                        <button class="btn btn-success" @click="createAbsence">
                            <font-awesome-icon icon="fa-solid fa-plus" />
                        </button>
                    </div>
                </div>
            </vue-good-table>
            <blank-modal ref="absenceModal" :modal-id="$util.htmlId()">
                <template #modalHeader>
                    <p>Absences</p>
                </template>
                <template #modalBody>
                    <div>
                        <p for="delegataire">Délégataire</p>
                        <input
                            id="delegatire"
                            type="text"
                            readonly
                            class="form-control"
                            :placeholder="`${delegator}`"
                        />
                    </div>
                    <div :class="{ content: isActive }">
                        <div class="pt-3">
                            <p>Date début d'absence - date fin d'absence</p>
                            <DateRangePicker
                                :ranges="false"
                                :show-ranges="false"
                                :time-picker-increment="1"
                                @select="selectFinish"
                                @start-selection="sartSelection"
                                :timePicker="timePickerShow"
                                @toggle="onToggle"
                                opens="center"
                                :locale-data="{
                                    firstDay: 1,
                                    format: 'dd-mm-yyyy HH:MM',
                                    daysOfWeek: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
                                    monthNames: [
                                        'Janvier',
                                        'Février',
                                        'Mars',
                                        'Avril',
                                        'Mai',
                                        'Juin',
                                        'Juillet',
                                        'Août',
                                        'Septembre',
                                        'Octobre',
                                        'Novembre',
                                        'Décembre',
                                    ],
                                }"
                                v-model="dateRange"
                                :date-format="dateFormat"
                                :autoApply="true"
                            >
                                <template #footer>
                                    <div></div>
                                </template>
                            </DateRangePicker>
                        </div>
                        <div>
                            <div class="pt-2">
                                <div class="d-flex">
                                    <p>Délégué</p>
                                    <div
                                        v-if="listUserIsUpdating"
                                        class="uk-margin-left uk-inline pt-1"
                                        uk-spinner="ratio:0.5"
                                    ></div>
                                </div>
                                <multiselect
                                    v-model="delegate"
                                    :options="options"
                                    placeholder=""
                                    label="name"
                                    @open="isActive = true"
                                    @close="isActive = !isActive"
                                    track-by="name"
                                    selectLabel="Cliquez pour sélectionner"
                                    deselectLabel="Cliquez pour désélectionner"
                                >
                                    <template slot="noOptions">Aucun résultats</template>
                                    <template slot="noResult">Aucun élément trouvé</template>
                                </multiselect>
                            </div>
                        </div>
                    </div>
                </template>
                <template #modalFooterExtra>
                    <button @click="onSave" class="btn btn-primary">
                        Enregistrer
                        <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                    </button>
                </template>
            </blank-modal>
            <blank-modal ref="modalDelete" :modal-id="$util.htmlId()">
                <template #modalHeader>
                    <legend class="uk-legend">Suppression</legend>
                </template>
                <template #modalBody> Voulez-vous supprimer ? </template>
                <template #modalFooterExtra>
                    <button class="btn btn-primary" @click="onDeleteOk">
                        Oui
                        <div v-if="loading" class="uk-margin-left uk-inline" uk-spinner="ratio:0.5"></div>
                    </button>
                </template>
            </blank-modal>
        </div>
    </div>
</template>

<script>
import store from '@/store'
import { mapState } from 'vuex'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
    name: 'Absences',
    components: { DateRangePicker },
    data: () => ({
        loading: false,
        timePickerShow: false,
        isActive: false,
        delegate: null,
        delegator: null,
        isUpdate: false,
        idAbsence: null,
        dateRange: {
            startDate: null,
            endDate: null,
        },
        columns: [
            {
                label: 'Délégataire',
                field: 'delegatorUser.userInfos.firstName',
            },
            {
                label: 'Délégué',
                field: 'delegateUser.userInfos.lastName',
            },
            {
                label: "Date début d'absence",
                field: 'startDate',
            },
            {
                label: "Date fin d'absence",
                field: 'endDate',
            },
            {
                label: 'Actions',
                field: 'actions',
                width: '1%',
            },
        ],
        pagination: {
            enabled: true,
            mode: 'records',
            perPage: 10,
            position: 'bottom',
            perPageDropdown: [10, 25, 50],
            dropdownAllowAll: false,
            setCurrentPage: 1,
            jumpFirstOrLast: true,
            firstLabel: 'Début',
            lastLabel: 'Fin',
            nextLabel: 'Suiv.',
            prevLabel: 'Préc.',
            rowsPerPageLabel: 'Lignes par page ',
            ofLabel: 'de',
            pageLabel: 'page',
            allLabel: 'Tous',
        },
    }),
    methods: {
        sartSelection(startDate) {
            const today = new Date()
            setTimeout(() => {
                if (startDate.getDate() === today.getDate()) {
                    this.dateRange.startDate = new Date()
                    this.dateRange.endDate = new Date()
                    if (this.timePickerShow) {
                        document.querySelector('.hourselect').value = new Date().getHours()
                        document.querySelector('.minuteselect').value = new Date().getMinutes()
                    }
                }
            }, 300)
        },
        selectFinish({ startDate, endDate }) {
            this.timePickerShow = true
            setTimeout(() => {
                const today = new Date()
                if (startDate.getDate() === today.getDate()) {
                    this.disableDatePast(endDate)
                } else {
                    const hourNow = new Date().getHours()
                    const minuteNow = new Date().getMinutes()
                    const hourSelect = document.querySelector('.hourselect')
                    for (let i = 0; i < hourNow; i++) {
                        hourSelect.options[i].disabled = false
                    }
                    const minuteSelect = document.querySelector('.minuteselect')
                    for (let i = 0; i < minuteNow; i++) {
                        minuteSelect.options[i].disabled = false
                    }
                }
            }, 100)
        },
        dateFormat(classes, date) {
            if (!classes.disabled) {
                const today = new Date()
                const yesterday = new Date(today)
                yesterday.setDate(yesterday.getDate() - 1)
                classes.disabled = date.getTime() - 62345678 < yesterday
            }
            return classes
        },
        onToggle(status) {
            if (!status) {
                this.timePickerShow = false
            }
            if (status === true && this.dateRange.startDate != null && this.dateRange.endDate != null) {
                setTimeout(() => {
                    this.disableDatePast()
                }, 200)
            }
            this.isActive = status
        },
        disableDatePast(endDate) {
            if (this.timePickerShow) {
                let hourNow = new Date().getHours()
                let minuteNow = new Date().getMinutes()
                this.dateRange.startDate = new Date()
                this.dateRange.endDate = endDate
                document.querySelector('.hourselect').value = hourNow
                document.querySelector('.minuteselect').value = minuteNow
                const hourSelect = document.querySelector('.hourselect')
                for (let i = 0; i < hourNow; i++) {
                    hourSelect.options[i].disabled = true
                }
                const minuteSelect = document.querySelector('.minuteselect')
                for (let i = 0; i < minuteNow; i++) {
                    minuteSelect.options[i].disabled = true
                }
            }
        },
        clearInput() {
            this.delegate = ''
            this.dateRange = {
                startDate: null,
                endDate: null,
            }
        },
        createAbsence() {
            this.isUpdate = false
            this.delegator = this.userFullName
            this.clearInput()
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.absenceModal.$el).show()
        },
        onSave() {
            const data = {
                userDelegate: this.delegate.id,
                startDate: this.dateRange.startDate,
                endDate: this.dateRange.endDate,
            }
            if (!this.isUpdate) {
                this.add(data)
                return
            }
            this.$http
                .updateAbsences(this.idAbsence, data)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.absenceModal.$el).hide()
                    store.dispatch('absences/updateAbsencesList')
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
                .then((this.loading = false))
        },
        add(data) {
            this.loading = true
            this.$http
                .addAbsences(data)
                .then(() => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.absenceModal.$el).hide()
                    store.dispatch('absences/updateAbsencesList')
                })
                .catch((e) => {
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
                .then((this.loading = false))
        },
        onDelete(data) {
            this.idAbsence = data.id
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).show()
        },
        onDeleteOk() {
            this.$http
                .deleteAbsences(this.idAbsence)
                .then(() => {
                    store.dispatch('absences/updateAbsencesList')
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                })
                .catch((e) => {
                    this.$bootstrap.Modal.getOrCreateInstance(this.$refs.modalDelete.$el).hide()
                    this.$store.dispatch('notifications/showWarning', e.response.data.exception.message)
                })
        },
        onUpdate(props) {
            this.isUpdate = true
            this.idAbsence = props.id
            this.fillInput(props)
            this.$bootstrap.Modal.getOrCreateInstance(this.$refs.absenceModal.$el).show()
        },
        fillInput(data) {
            this.delegator = data.delegatorUser.userInfos.firstName + ' ' + data.delegatorUser.userInfos.lastName
            const user = {
                name: data.delegateUser.userInfos.firstName + ' ' + data.delegateUser.userInfos.lastName,
                id: data.delegateUser.id,
            }
            this.delegate = user
            this.dateRange.startDate = data.startDate
            this.dateRange.endDate = data.endDate
        },
    },
    computed: {
        ...mapState('users', { usersList: 'list', listUserIsUpdating: 'listIsUpdating' }),
        ...mapState('absences', { absencesList: 'list', absencesListIsUpdating: 'listIsUpdating' }),
        options() {
            const users = this.usersList.map((item) => {
                return {
                    name: item.userInfos.firstName + ' ' + item.userInfos.lastName,
                    id: item.id,
                }
            })
            return users.filter((item) => item.id !== this.$store.state.login.user.userData.id)
        },
        userFullName() {
            return (
                this.$store.state.login.user.userData.userInfos.firstName +
                ' ' +
                this.$store.state.login.user.userData.userInfos.lastName
            )
        },
    },
    beforeRouteEnter(to, from, next) {
        store.dispatch('users/updateSuezList')
        store.dispatch('absences/updateAbsencesList')
        next()
    },
    beforeRouteUpdate(to, from, next) {
        store.dispatch('users/updateSuezList')
        next()
    },
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.content {
    min-height: 496px;
}

.reportrange-text[data-v-1ebd09d2] {
    width: 303px;
}

i {
    cursor: pointer;
}

.vue-daterange-picker[data-v-1ebd09d2] {
    min-width: 100%;
}

@media only screen and (max-width: 560px) {
    .show-calendar {
        position: absolute !important;
        width: 100vw !important;
        height: auto !important;
        margin-top: 100% !important;
    }

    .calendars-container {
        justify-content: center !important;
        align-items: center !important;
        text-align: center !important;
    }

    .drp-buttons {
        text-align: center !important;
    }
}

@media only screen and (min-width: 760px) {
    .daterangepicker.ltr.show-calendar.show-ranges.openscenter.linked {
        left: 100% !important;
    }
}
</style>
